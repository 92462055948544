/* eslint-disable react/jsx-filename-extension */
// import global styles from tailwind
// import "./src/styles/globals.css";
import {
	QueryClient,
	QueryClientProvider,
} from "react-query";
import "./src/assets/fonts/franklin-gothic-urw-book/franklin-gothic-urw-book.css";
import "./src/assets/fonts/typekit-fonts.css";
import "./src/styles/colors.css";
import CurrentSupporterInfoContextProvider from "./src/components/context-providers/current-supporter-info-context";

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
	<QueryClientProvider client={queryClient}>
		<CurrentSupporterInfoContextProvider>
			{element}
		</CurrentSupporterInfoContextProvider>
	</QueryClientProvider>
);
