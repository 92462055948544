import React, { useState, useContext } from "react";

// ======TYPES========
export interface SupporterInfo {
	id: string;
	firstname: string;
	lastname: string;
	email: string;
}

// =====COMPONENT=======

const StateContext =
	React.createContext<SupporterInfo | null>(null);
const UpdateStateContext = React.createContext<
	React.Dispatch<React.SetStateAction<SupporterInfo | null>>
>(() => {});

export function useCurrentSupporterInfoContext() {
	return useContext(StateContext);
}

export function useCurrentSupporterInfoUpdateContext() {
	return useContext(UpdateStateContext);
}

const CurrentSupporterInfoContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [supporterInfo, setSupporterInfo] =
		useState<SupporterInfo | null>(null);

	return (
		<StateContext.Provider value={supporterInfo}>
			<UpdateStateContext.Provider value={setSupporterInfo}>
				{children}
			</UpdateStateContext.Provider>
		</StateContext.Provider>
	);
};

export default CurrentSupporterInfoContextProvider;
